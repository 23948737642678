import React, { Fragment } from "react"

import Sub from "~layout/sub"

const crumbs = [
    { text: "Privacy Policy", to: "/privacy-policy" },
]

const PrivacyPolicyPage = () => {
    let content = (
        <Fragment>
            <h1>Privacy Policy</h1>
            {/* <p>Last updated: 14 October 2014.</p> */}
            {/* <p>Last updated: 4 August 2021.</p> */}
            <p>Last updated: 22 August 2023.</p>
            
            <h2>(1) Data Protection Privacy Policy</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}> 
                    <li>CommonTown Pte Ltd (“CommonTown”, “we” or “us”) recognises the importance and sensitivity of any personal data we may collect from you from time to time when you use our website, mobile applications and software products (collectively, our "Services"). This Data Protection Privacy Policy ("Policy") sets out the basis which we may collect, use, disclose or otherwise process personal data  in connection with your use of our Services in accordance with the Singapore Personal Data Protection Act (No. 26 of 2012) (“PDPA”). By using the Services, you consent to the data practices described in this Policy. <br/><br/></li>
                    <li>Please read this Policy carefully to ensure that you are aware of and understand what personal data we may collect from you, how we may use that personal data, how you can get in touch with us if you wish to limit our use of that personal data and more generally of the steps we take to protect your privacy. <br/><br/></li>
                    <li>References in this Policy to our "partners", include our strategic partners, affiliates, agents, third party service providers and other related third parties from time to time. Other terms in this Policy have the meanings given to them herein or, where the context so permits, in our Terms of Use or in the PDPA.<br/><br/></li>
                    <li>CommonTown may change this Policy from time to time by updating this page. Please check this page frequently for updates or changes to our Policy. The “Last updated” section at the top of this Policy will indicate the last time it has been changed. Your continued use of CommonTown’s Services is regarded as your acknowledgement and acceptance of such changes to the Policy.<br/><br/></li>
                </ol>
            </p>

            <h2>(2) What is personal data?</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}> 
                    <li>Personal data is data, whether true or not, about you, from which you can be identified either on its own or together with other information to which we have or are likely to have access. <br/><br/></li>
                    <li>However, please note that the PDPA imposes no specific requirements in relation to our handling of data which relates to your business contact information, such as your business position and business contact details, where you have provided such information to us not only for personal purposes. <br/><br/></li>
                    <li>
                        Depending on the nature of Services that you or your Organization subscribe to, the following are examples of information or personal data we may collect:  <br/><br/>
                        <ol style={{"list-style-type": "lower-roman"}}> 
                            <li>User’s Account and Survey Information: The type of personal data we may collect from you for the purposes of administering any registration may include the following: first name, last name, username, password, gender, date of birth or age, postal address, contact number, e-mail address, profile photo, personal portfolio (including photographs or videos of another person including children), NRIC/passport or other identification information, city of birth and nationality, where applicable. <br/><br/></li>
                            <li>Mobile Applications Information: We may collect data such as your device ID, device location, and other personally identifiable information. <br/><br/></li>
                            <li>Internet Information: We may automatically collect information relating to your use of our website(s) and Services, including but not limited to your internet service provider; operating systems; computer browser information; domain names; IP addresses; access times; pages visited and so forth. This data may be aggregated or anonymised which means that we will not be able to identify you individually.<br/><br/></li>
                            <li>Financial Information: We may also collect certain financial information such as bank account and other payment information for GIRO payment purposes when you or your organization subscribe to our Fee Management module with GIRO feature from us.<br/><br/></li>
                            <li>Through the use of our services, you may provide us with information or upload images. This can happen when you share information with your organization using modules such as Messaging or Travel Declaration, depending on the specific services you or your organization use or subscribe to.<br/><br/></li>
                        </ol>
                    </li>
                </ol>
            </p>

            <h2>(3) Ways we collect your personal data</h2>
            <p>We may collect your personal information in the following ways when you or your Organization* subscribe to our Services or register an account with us to:
                <ol style={{"list-style-type": "lower-alpha", "margin-top": 0}}> 
                    <li>use our Services</li>
                    <li>sign up for alerts or newsletters</li>
                    <li>contact us with a question or request for assistance or to provide feedback to</li>
                    <li>participate in a survey or contest organized by us</li>
                    <li>visit our website and applications (through the use of cookies and other technologies).</li>
                </ol>
            </p>
            <p>* Your Organization may be:
                <ol style={{"list-style-type": "lower-roman", "margin-top": 0}}> 
                    <li>Your child’s school (e.g. if you are a parent or guardian)</li>
                    <li>Your school (e.g. if you are a student)</li>
                    <li>The company or organization you work in (e.g. if you are a teacher or principal)</li>
                </ol>
            </p>
            <p>We may also process the personal data of you or your child if they are provided by you or your Organization. You agree and consent to us processing these personal data in accordance with the terms of this Policy.</p>
            <p>If you give us information about another person(s), you:
                <ul style={{"margin-top": 0}}> 
                    <li>confirm that you are appointed by them or otherwise able to give consent on their behalf for the collection and/or processing of their personal data by us as described and in accordance with this Policy, and to </li>
                    <li>receive and accept on their behalf the terms of this Policy; and</li>
                    <li>warrant that you have obtained their consent for us to collect and/or process their personal data as described and in accordance with this Policy.</li>
                </ul>
            </p>

            <h2>(4) What we do with the information gathered?</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                    <li>We may collect and use your personal data for any or all of the following purposes:
                        <ol style={{"list-style-type": "lower-roman", "margin-top": 0}}> 
                            <li>performing obligations in the course of or in connection wth our provision of the Services to you (this includes but is not limited to using your personal information to help us create, develop, operate and deliver customized content to you or your Organization and to improve our Services for our customers);</li>
                            <li>verifying your identity (eg. using your identification number and date of birth to do so); </li>
                            <li>responding to, handling, and processing queries, requests, applications, complaints and feedback from you;</li>
                            <li>managing your relationship with us;</li>
                            <li>processing payment transactions (where applicable);</li>
                            <li>sending you marketing information relating to our Services or products including notifying you of our marketing events, initiatives, contests and other promotions;</li>
                            <li>complying with any applicable laws, codes of practice, guideline or rules, or assisting in law enforcement and investigations conducted by any governmental and/or regulatory authority; </li>
                            <li>for any other purposes for which you have provided the information and/or for purposes as described in this Policy;</li>
                            <li>transmitting to any unaffiliated third parties including our partners, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes; and</li>
                            <li>for any other incidental business purposes related to or in connection with the above.</li>
                        </ol>
                        <br/>
                    </li>
                    <li>We may disclose your personal data where such disclosure is required for performing obligations in the course of or in connection with our provision of the Services subscribed, or to partners we have engaged for or in relation to this.<br/><br/></li>
                    <li>To better understand your needs and provide you with better services, we may use your personal information from time to time for:
                        <ul style={{"margin-top": 0}}> 
                            <li>internal record keeping and data analysis </li>
                            <li>communication to provide service support</li>
                            <li>communication about purchases and changes to our terms, conditions and policies</li>
                            <li>communication about new products, special offers, updates or other information which we think may be relevant to you.</li>
                        </ul>
                        <br/>
                    </li>
                    <li>We may aggregate non-personal information such as usage activities and preferences to understand customer behavior and help us provide more useful information to our customers<br/><br/></li>
                    <li>From time to time, we may use your non-personal information for market research purposes. However, we will never share or sell your data to anyone else for any reason. <br/><br/></li>
                    <li>In the event that we do combine non-personal information with personal data, the combined information will be treated as personal data for as long as it is used. <br/><br/></li>
                    <li>The purposes listed in the this Policy may continue to apply even in situations where your relationship with us has been terminated or altered in any way, for a reasonable period thereafter (including, where applicable, a period to enable us to enforce our rights under any contract or our Terms of Use with you or your Organization).<br/><br/></li>
                </ol>
            </p>

            <h2>(5) Use of cookies and links to third party websites</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                <li>A “cookie” is a commonly used automated data collection tool used by internet browsers. It is a small amount of data that is sent to a browser from a web server and gets saved on the hard drive of the computer that you are using. Cookies and similar tools collect standard information from your browser such as browser type, browser language, and the website address you came from when you visited our site.<br/><br/></li>
                <li>CommonTown may use web “cookies”, “pixel tags”, “clear GIFs” or other similar technologies in connection with website pages and HTML-formatted e-mail messages to track the actions of website users and e-mail recipients, in order to:
                    <ul style={{"margin-top": 0}}> 
                        <li>determine the success of marketing campaigns </li>
                        <li>aggregate statistics about website usage and click rates</li>
                        <li>make navigation to our website(s) easier by not requiring you to enter your credentials repeatedly on login pages or by showing you targeted content according to your interests or subscribe services</li>
                    </ul>
                    <br/>
                </li>
                <li>If you do not want information collected through the use of cookies, you may adjust your browser settings to automatically decline cookies, or manually choose/decline the transfer of a cookie from a particular web site, to your computer. If you set your browser to reject all cookies, you will be unable to use our Services that require login or customization in order to participate. If you choose to accept cookies from our websites and applications, you agree to grant us consent to set cookies on your computer.<br/><br/></li>
                <li>Our website(s) may contain links to other websites. This Policy only applies to our websites so when you link to other websites, you should read their own privacy policies. <br/><br/></li>
                </ol>
            </p>

            <h2>(6) Protecting your personal data</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                    <li>CommonTown strives to use commercially reasonable industry standards efforts to protect the confidentiality of your personal data that is in our possession or under our control, eg. taking appropriate security measures to prevent unauthorised access, collection, use, disclosure, copying modification, disposal or similar risks in relation to such personal data. <br/><br/></li>
                    <li>However, you should be aware that no data transmission over the internet or method of electronic storage can be guaranteed as totally secure and therefore, unfortunately, we cannot ensure or warrant the total security of any information stored or transmitted from or to our online products or Services. Any such transmission is at your own risk. While security cannot be be guaranteed, we strive to protect the security of your data and regularly review developments in relation to security and encryption technologies. <br/><br/></li>
                </ol>
            </p>

            <h2>(7) Accuracy of personal data</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                    <li>We will take reasonable efforts to ensure that your personal data is accurate and complete (as provided by you or your Organization) when we disclose it to our partners and if we disclose it to other third parties in accordance with this Policy or if it is likely to be used by us to make a decision affecting you. <br/><br/></li>
                    <li>Please note that, in most circumstances, we will presume that personal data directly provided from you or your Organization will be current, accurate and complete. However, depending on the circumstances, we may decide to contact you to ensure that this is the case or to ask that you verify that the personal data we hold in relation to you is up to date. <br/><br/></li>
                </ol>
            </p>

            <h2>(8) How you can access and amend your personal information</h2>
            <p>You may access, review and amend your personal information at any time by signing into your user account (where applicable) and selecting “Update Profile” (or equivalent), or by contacting our Support Helpdesk.</p>

            <h2>(9) Retention of personal data</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                    <li>We will only hold your personal data for as long as required for the purposes for which it was collected or as required or permitted by law. <br/><br/></li>
                    <li>We will regularly review and assess whether personal data can legitimately be retained, having regard to the purpose for which it was collected and any other legal or business purposes for which retention may be necessary. <br/><br/></li>
                    <li>Where we are satisfied that we no longer need to retain your personal data, we will take prompt action to ensure that we will no longer hold on to it, either by destroying or removing your personal data from our systems, files and other records completely and permanently or by removing any identifying information so that it becomes anonymised. <br/><br/></li>
                </ol>
            </p>

            <h2>(10) Transfers of personal data outside of Singapore</h2>
            <p>We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we will obtain the consent of your Organization for the transfer to be made and will take steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</p>

            <h2>(11) How you can withdraw consent for the use of your personal information</h2>
            <p>
                <ol style={{"list-style-type": "lower-alpha"}}>
                    <li>You may contact our Support Helpdesk to withdraw the consent to any or all use of your personal data. If you do not wish to receive any updates or promotional materials from us, you may contact us to "unsubscribe” from these communication emails. <br/><br/></li>
                    <li>Depending on the nature and scope of your request, please note that we may not be able to provide or continue to provide our Services to you. This may constitute a breach in our contractual obligation to you or your Organization and we shall not be held liable nor responsible to you or your Organization for such a breach arising from your request. We will notify you before completing the processing of such a request; should you decide to cancel your withdrawal of consent, please contact us as described in (a) above. <br/><br/></li>
                    <li>Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal data where such collection, use and disclosure without consent is permitted or required under applicable law. <br/><br/></li>
                </ol>
            </p>

            <h2>(12) How you can contact us</h2>
            <p>
                If you have any questions or concerns about this Policy, or if you would like to make any request relating to your personal information, please contact our Data Protection Officer at:<br/>
                Email: <a href="mailto:ctdpo@commontown.com" rel="nofollow">ctdpo@commontown.com</a><br/>
                Tel: +65 6634-6233 (Mon-Fri 9am-6pm)
            </p>
            <br/>
            <p> 
                For technical support please contact our Helpdesk Support at:<br/>
                Email: <a href="mailto:support@commontown.com" rel="nofollow">support@commontown.com</a><br/>
                Tel: +65 6848-8900 (Mon-Fri 8am-6pm)
            </p>
        </Fragment>
    )

    return (
        <Sub title="Privacy Policy" content={content} crumbs={crumbs} />
    )
}

export default PrivacyPolicyPage
